<template>
  <CCard id="table-child-pool">
    <CDataTable
      :hover="hover"
      :striped="striped"
      :bordered="bordered"
      :small="small"
      :fixed="fixed"
      :items="items"
      :fields="fields"
      :items-per-page="10"
      :dark="dark"
      :pagination="true"
    >
      <td slot="#" slot-scope="{ item, index }">{{ index + 1 + offset}}</td>
      <template #action="{item}">
        <td class="py-2">
          <div class="group-btn">
              <CLink :title="$t('TABLES_COMPONENT_TITLE_EDIT')" @click="handleEdit(item.id)" v-if="hasUpdate === true">
                  <img src="@/assets/images/ico-edit-user.svg" alt="" class="hand edit-icon"/>
              </CLink>
            <CLink @click="() => handleConfig(item.id)" :title="$t('TABLES_COMPONENT_TITLE_DETAIL')" v-if="hasDetail === true">
              <CIcon class="icon-config" :content="cilBarChart" />
            </CLink>
          </div>
        </td>
      </template>
    </CDataTable>
  </CCard>
</template>

<script>
import { cilBarChart, cilPencil } from '@coreui/icons';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'Table',
  data() {
    return {
      cilBarChart,
      cilPencil,
      hasUpdate: false,
      hasDetail: false
    };
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    bordered: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
    handleEdit: Function,
    handleConfig: Function,
    offset: {
      type: Number,
      default: 0
    }
  },
  computed: {
      ...mapState({
          userPermission: state => state.auth.userPermission,
      })
  },
  async mounted(){
      await this.checkPermission();
  },
  methods: {
      checkPermission(){
          if (this.userPermission.length > 0){
              this.userPermission.forEach(item => {
                  if (item.name === 'UPDATE_PARTNER'){
                      this.hasUpdate = true;
                  }
                  if (item.name === 'VIEW_STAT_PARTNER'){
                      this.hasDetail = true;
                  }
              });
          }
      }
  }
};
</script>

<style lang="scss">
#table-child-pool {
  overflow-x: auto;
  table{
    td{
      color: #4D5666;
    }
  }
  nav {
    float: right;
  }
  .group-btn {
    display: flex;
    .icon-edit {
      color: #5706ff;
      cursor: pointer;
      margin-right: 10px;
    }
    .icon-config {
      color: #f20058;
      cursor: pointer;
    }
    img{
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    img:hover{
        cursor: pointer;
    }
    span{
      margin-left: 8px;
    }
  }
}
</style>
