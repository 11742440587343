<template>
  <div class="childpool-wrap">
    <CCard>
      <CCardHeader class="justify-center">
        <CButton v-if="this.hasKey === true"
          color="success"
          shape="pill"
          class="mr-1 btn-custom-link"
          @click="goDirection('api-key')"
        >{{$t('DETAIL_POOL_PAGE_BUTTON_API_KEY_MANAGEMENT')}}</CButton>
        <CButton
          v-if="partner_type == 'AFFILIATE' && this.hasMemo === true"
          color="success"
          class="mr-1 btn-custom-link"
          shape="pill"
          @click="goDirection('grand-child')"
        >{{$t("DETAIL_POOL_PAGE_BUTTON_GRANDCHILD_POOL")}}</CButton>
        <CButton
          v-if="partner_type == 'CHILD'  && this.hasMemo === true"
          class="mr-1 btn-custom-link"
          color="success"
          shape="pill"
          @click="goDirection('transaction-memo')"
        >{{$t("DETAIL_POOL_PAGE_BUTTON_TRANSACTION_MEMO")}}</CButton>
        <CButton
          v-if="this.hasCommission === true"
          color="success"
          class="mr-1 btn-custom-link"
          shape="pill"
          @click="goDirection('commission')"
        >{{$t("DETAIL_POOL_PAGE_BUTTON_COMMISSION")}}</CButton>
      </CCardHeader>
      <CCardBody>
        <div class="mt-4">
          <Tables :items="items" :fields="fields" :offset="0" hover striped bordered/>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import Filters from "@/components/ChildPool/Filters";
import Charts from "@/components/ChildPool/Charts";
import Tables from "@/components/ChildPool/Tables";
import endpoints from "@/constants/endpoints";
import BigNumber from "bignumber.js";
import { mapState} from "vuex";
import responseHelper from '@/utils/response-helper';
export default {
  components: {
    Filters,
    Charts,
    Tables
  },
  data() {
    return {
      partner_type: "",
      fields: [
          { key: "#",label: "#", _classes: "text-left" },
          { key: "platform",label: this.$t("DETAIL_POOL_PAGE_LABEL_PLATFORM"), _classes: "text-left" },
          { key: "users",label: this.$t("DETAIL_POOL_PAGE_LABEL_USERS"), _classes: "text-right" },
          { key: "staking",label: this.$t("DETAIL_POOL_PAGE_LABEL_STAKING"), _classes: "text-right" },
          { key: "commission",label: this.$t("DETAIL_POOL_PAGE_LABEL_COMMISSION"), _classes: "text-right" }
          ],
      items: [],
      hasKey: false,
      hasMemo: false,
      hasCommission: false
    };
  },
  computed: {
    partnerId() {
      return this.$route.params.id;
    },
    ...mapState({
      partner: state => state.partner.partnerSelected,
      userPermission: state => state.auth.userPermission,
    })
  },
  async mounted() {
    let childpool = await this.getChildpool();
    if (!childpool) {
      return;
    }
    this.partner_type = childpool.partner_type;
    await this.getChildpoolCoinsInfo();
    await this.checkPermission();
  },
  /**
   * Check up router before render element
   */
  async beforeRouteEnter(to, from, next) {
    try {
      let id = to.params.id;
      if (!id) {
        next();
      } else {
        next(vm => {
          if (vm.partner.id !== id) {
            vm.$router.push("/admin/childpool");
          } else {
            return;
          }
        });
      }
      return true;
    }
    catch(err) {
      this.$notify({
        group: "notifications",
        type: "error",
        title: this.$t("DETAIL_POOL_PAGE_TITLE_NOTIFY_ERROR"),
        text: responseHelper.getErrorMessage(err),
      });
      return false;
    }
  },
  methods: {
    goDirection(value) {
      const {
        current: { params }
      } = this.$router.history;
      this.$router.push(`${params.id}/${value}`);
    },
    changeType(value) {
      this.timeUnits = this[value.toLowerCase()];
      this.selected = value;
    },
    async getChildpool() {
      try {
        const result = await this.$http.get(
          endpoints.getPartner(this.partnerId)
        );
        return result.data;
      } catch (err) {
        console.log(err);
        return null;
      }
    },
    /**
     * Get the list of childpool coins/fiats info anh assign to this.items to display in the talbe
     * @param
     * @return {boolean}
     */
    async getChildpoolCoinsInfo() {
      try {
        const params = {
          from: this.$moment(new Date(0)).format("MM/DD/YYYY"),
          to: this.$moment(new Date()).format("MM/DD/YYYY"),
        };
        // Async execute 2 $http function to booste the performance
        let [resStaking, resCommission] = await Promise.all([
          this.$http.get(endpoints.getItemsStakingLst(this.partnerId), {params: params}),
          this.$http.get(endpoints.getItemsCommissionLst(this.partnerId), {params: params})
        ]);
        let itemsStakingLst = resStaking.data.items;
        let itemsCommissionLst = resCommission.data.items;  
        // Merge 2 items list, each includes some different object property
        this.items = itemsStakingLst.map((item) => {
          return Object.assign({}, item, itemsCommissionLst.find((itemCommission) => itemCommission.platform === item.platform));
        });
        // Rename this.items property due to backend api property is not the same name as frontend UI requirement           
        this.items.forEach((item, idx) => {
          item.users = item.user;
          item.staking = BigNumber(item.balance).toFormat();
          if(item.amount === undefined ){
              item.commission = 0
          }else{
              item.commission = BigNumber(item.amount).toFormat();
          }
          delete item.user;
          delete item.balance;
          delete item.amount;
        });
        return true;
      } catch (err) {
        this.$notify({
          group: "notifications",
          type: "error",
          title: this.$t("DETAIL_POOL_PAGE_MSG_TITLE"),
          text: responseHelper.getErrorMessage(err),
        });
        return false;
      }   
    },
    checkPermission(){
        if(this.userPermission.length > 0){
            this.userPermission.forEach(items =>{
                if(items.name === 'VIEW_LIST_KEY_PARTNER'){
                    this.hasKey = true
                }
                if(items.name === 'VIEW_LIST_MEMO_PARTNER'){
                    this.hasMemo = true
                }
                if(items.name === 'VIEW_COMMISSION_STAT_PARTNER'){
                    this.hasCommission = true
                }
            })
        }
    }
  }
};
</script>
<style scoped lang="scss">
.btn-custom-link {
  min-width: 175px;
  padding: 0;
  width: 170px;
  height: 32px;
  border-radius: 32px;
  background: #3B4B7E;
  border-color: unset;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}
.btn-success:not(:disabled):not(.disabled):active{
  background: #3B4B7E;
}
.justify-center {
  display: flex;
  align-items: center;
}
</style>
