<template>
  <CChartBar
    :datasets="defaultDatasets"
    :labels="timeUnits"
  />
</template>

<script>
import { CChartBar } from '@coreui/vue-chartjs'

export default {
  name: 'Chart',
  components: { CChartBar },
  props: {
    label: String,
    timeUnits: [String, Array]
  },
  computed: {
    defaultDatasets () {
      return [
        {
          label: this.label,
          backgroundColor: '#f87979',
          data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
        }
      ]
    }
  }
}
</script>
